<template>
	<div class="OrderList">
		<div class="title">{{$t('orderDetail.detail')}}</div>
		<div class="orderList">
			<div class="item" v-for="val in list">
				<div class="col">
					<div class="name">{{$t('orderDetail.content')}}</div>
					<div class="val underline" @click="$router.push(`/OrderDetail?id=${val.orderId}`)">{{val.sn}}</div>
				</div>
				<div class="col">
					<div class="name">{{$t('orderDetail.time')}}</div>
					<div class="val">{{val.createTime}}</div>
				</div>
				<div class="col">
					<div class="name">{{$t('cart.num')}}</div>
					<div class="val">{{val.goods.reduce((base,item)=>{ return base+parseInt(item.num) },0)}}</div>
				</div>
				<div class="col">
					<div class="name">{{$t('cart.total')}}</div>
					<div class="val">€{{val.totalAmount}}</div>
				</div>
				<div class="col">
					<div class="name">{{$t('orderDetail.status')}}</div>
					<div class="val">{{val.flagText}}</div>
				</div>
				<div class="col">
					<div class="name" style="height: 20px;"></div>
					<div class="val underline" @click="handleCancel(val.orderId)">{{$t('address.delete')}}</div>
				</div>
			</div>
			<div class="total">{{$t('post.total')}} {{list.length}} {{$t('post.orderCount')}}</div>
		</div>
		<Logistic v-if="dialog.logistic" @close='close'></Logistic>
	</div>
</template>

<script>
	import Api from '../../api/request.js'
	import Logistic from './logistic.vue'
	export default {
		name: 'OrderList',
		components: {
			Logistic
		},
		data() {
			return {
				page: {
					size: 999,
					page: 1
				},
				list: [],
				dialog: {
					logistic: false
				}
			};
		},
		created() {
			this.getList();
		},
		methods: {
			getList() {
				// this.$loading.start();
				Api.Order.list({
					page: this.page.page,
					pageLimit: this.page.size,
				}).then(res => {
					this.list = res.data.data.list;
					// this.$loading.done();
				})
			},
			handleCancel(order_id) {
				this.$dialog.confirm('Confirma la orden de eliminación' + "?").then((res) => {
					Api.Order.cancel({
						order_id
					}).then(res => {
						this.getList();
					})
				});
			},
			close() {
				this.dialog.logistic = false;
			}
		}
	};
</script>

<style lang="less" scoped>
	.title {
		font-size: 18px;
		font-weight: bold;
		line-height: 65px;
	}

	.orderList {
		padding: 0 40px;
		overflow: hidden;
		border: 1px solid #D9D9D9;

		.total {
			font-size: 14px;
			font-weight: 400;
			color: #000000;
			line-height: 20px;
			padding: 20px 0 40px;
		}

		.item {
			display: flex;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				height: 1px;
				bottom: 0;
				left: 0;
				right: 0;
				background: #D9D9D9;
			}

			.col {
				width: 200px;
				padding: 40px 0;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;

				.name {
					margin-bottom: 40px;
				}

				.underline {
					text-decoration: underline;
					cursor: pointer;
				}
			}

			.col+.col {
				margin-left: 15px;
			}
		}
	}

	@media screen and (max-width: 500px) {
		.title {
			font-size: 4vw;
			line-height: 12vw;
		}

		.orderList {
			padding: 0 3vw;

			.total {
				font-size: 3.5vw;
				padding: 4vw 0;
			}

			.item {
				flex-direction: column;
				padding-top: 3vw;

				.col {
					padding: 1vw 0;
					font-size: 3.2vw;
					line-height: 6vw;
					width: 100%;
					display: flex;
					justify-content: space-between;

					.name {
						margin-bottom: 0;
					}
				}
			}
		}
	}
</style>
